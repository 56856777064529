import React from 'react';
import { inject, observer } from 'mobx-react';
import { FieldArray } from 'react-final-form-arrays';
import {
  Field, renderField, renderHubspotFieldArrayEvents, RenderFacebookFieldArrayEvents,
} from "../../../../utils/formFieldHelper";
import { Toggle } from '../../../ApeUI';
import { required } from '../../../../utils/validation';
import Form from '../../../Settings/Form/Form';
import { StoresEnum } from '../../../../stores';

import './PixelsSettings.scss';

export class PixelsSettings extends React.Component {
  state = {
    section: 'hubspot',
  };

  renderHubspotFields = (onSubmit, readOnly) => (
    <>
      <Field name="pixels.hubspot.accountId" type="text" component={renderField} label="HubSpot ID" validate={required} readOnly={readOnly} />
      <FieldArray name="pixels.hubspot.events" component={renderHubspotFieldArrayEvents} onSubmit={onSubmit} readOnly={readOnly} />
    </>
  );

  renderFbPixelsFields = (onSubmit, readOnly) => (
    <>
      <Field name="pixels.fb.accountId" type="text" component={renderField} label="Facebook pixel id" validate={required} readOnly={readOnly} />
      <FieldArray name="pixels.fb.events" component={RenderFacebookFieldArrayEvents} onSubmit={onSubmit} readOnly={readOnly} />
    </>
  );

  render() {
    const { channelStore: { channel, readOnly, updateChannel } } = this.props;
    const { shouldTrack } = channel;
    const { section } = this.state;
    const selectedStyle = { background: 'white' };
    return (
      <>
        <div className="section">
          <div className="form-title" style={{ display: 'grid', gridTemplateColumns: '150px 1fr' }}>
            <div
              style={{
                cursor: 'pointer', display: 'inline-block', lineHeight: '32px', textAlign: 'center',
              }}
              onClick={() => this.setState({ section: 'hubspot' })}
            >
              Apester Tracking
            </div>

            {' '}
          </div>

          <div className="pixels-form">
            <Toggle
              label="Enable user tracking"
              toggled={shouldTrack}
              disabled={readOnly}
              onChange={newValue => {
                updateChannel({
                  shouldTrack: newValue,
                });
              }}
            />
          </div>

        </div>
        <div className="section">
          <div className="form-title" style={{ display: 'grid', padding: 'unset', gridTemplateColumns: '190px 190px 1fr' }}>
            <div
              style={{
                cursor: 'pointer', display: 'inline-block', lineHeight: '32px', textAlign: 'left', padding: '0 30px', ...((section === 'hubspot' && selectedStyle) || {}),
              }}
              onClick={() => this.setState({ section: 'hubspot' })}
            >
              HubSpot Pixel
            </div>
            <div
              style={{
                cursor: 'pointer', display: 'inline-block', lineHeight: '32px', textAlign: 'left', padding: '0 30px', ...((section === 'facebook' && selectedStyle) || {}),
              }}
              onClick={() => this.setState({ section: 'facebook' })}
            >
              Facebook Pixel
            </div>
            {' '}
          </div>

          {section === 'hubspot' ? (
            <Form
              initialValues={channel}
              fields={this.renderHubspotFields(updateChannel, readOnly)}
              onSubmit={updateChannel}
              hideCancelButton
              hideApproveButton={readOnly}
              className="pixels-form hubspot"
              hasFieldArray
            />
          ) : (
            <Form
              initialValues={channel}
              fields={this.renderFbPixelsFields(updateChannel, readOnly)}
              onSubmit={updateChannel}
              hideCancelButton
              hideApproveButton={readOnly}
              className="pixels-form fb"
              hasFieldArray
            />
          )}
        </div>
      </>
    );
  }
}

export default inject(StoresEnum.CHANNEL)(observer(PixelsSettings));
